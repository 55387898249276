<template>
  <div v-if="forms">
    <form-address :data="forms" :disabled="disabled" @valid="updateAddress"/>
  </div>
</template>

<script>
import formAddress from './formSupplierAddress'

export default {
  name: 'editAddress',
  components: {
    formAddress
  },
  props: {
    disabled: {
      default: true
    }
  },
  data() {
    return {
      forms: {}
    }
  },
  created() {
    this.getSupplierAddress()
  },
  methods: {
    async getSupplierAddress() {
      let {data} = await this.$api.supplierAddress.get(this.$route.params.address_id)
      this.forms = {
        city: data.city,
        contactName: data.contact_name,
        country: data.country,
        houseNumber: data.house_number,
        isDeliveryAddress: data.is_delivery_address,
        phoneNumber: data.phone_number,
        postalCode: data.postal_code,
        street: data.street
      }
    },
    async updateAddress() {
      try {
        let response = await this.$api.supplierAddress.updateAddress(
            this.form.postalCode, this.form.city, this.form.houseNumber, this.form.street, this.form.country,
            this.form.isDeliveryAddress, this.addressId
        )
      } catch (e) {
        this.$toasted.show('Kan niet opslaan, probeer opnieuw of vraag hulp bij IT')
      }
    }
  }
}
</script>
